<template>
  <b-form-group
    label-for="state"
  >
    <v-select
      id="state"
      label="state"
      :reduce="state => state.state"
      :options="states"
      placeholder="Select State"
      @input="onSelectState"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'

export default {
  name: 'StateSelect',
  components: {
    vSelect,
  },
  props: {
    country: { type: String, default: '' },
    classification: { type: String, default: '' },
  },
  data() {
    return {
      states: [],
    }
  },
  watch: {
    country() {
      this.getStates()
    },
  },
  methods: {
    getStates() {
      this.$entities.post('internalops/get-organization/states', {
        countries: this.country,
        business: !!this.$attrs.business,
        classification: this.classification,
      }).then(res => {
        this.states = res.data.data
      })
    },
    onSelectState(stateId) {
      this.$emit('update:state', stateId)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
