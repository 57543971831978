<template>
  <b-form-group
    label-for="city"
  >
    <v-select
      id="city"
      label="city"
      :reduce="city => city.city"
      :options="cities"
      placeholder="Select City"
      @input="onSelectCity"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'

export default {
  name: 'CitySelect',
  components: {
    vSelect,
  },
  props: {
    country: { type: String, default: '' },
    state: { type: String, default: '' },
    classification: { type: String, default: '' },
  },
  data() {
    return {
      cities: [],
    }
  },
  watch: {
    country() {
      this.getCities()
    },
    state() {
      this.getCities()
    },
  },
  created() {
    this.getCities()
  },
  methods: {
    getCities() {
      this.$entities.post('internalops/get-organization/cities', {
        countries: this.country,
        states: this.state,
        business: !!this.$attrs.business,
        classification: this.classification,
      }).then(res => {
        this.cities = res.data.data
      })
    },
    onSelectCity(cityId) {
      this.$emit('update:city', cityId)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
