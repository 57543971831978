<template>
  <b-form-group
    label-for="country"
  >
    <v-select
      id="country"
      label="country"
      :reduce="country => country.country"
      :options="countries"
      placeholder="Select Country"
      @input="onSelectCountry"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'

export default {
  name: 'CountrySelect',
  components: {
    vSelect,
  },
  props: {
    classification: { type: String, default: '' },
  },
  data() {
    return {
      countries: [],
    }
  },
  created() {
    this.getCountries()
  },
  methods: {
    getCountries() {
      this.$entities.get('internalops/get-organization/countries', {
        params: {
          business: !!this.$attrs.business,
          classification: this.classification,
        },
      }).then(res => {
        this.countries = res.data.data
      })
    },
    onSelectCountry(countryId) {
      this.$emit('update:country', countryId)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
